<template>
  <div class="leaveWord">
    <van-nav-bar :title="$t('留言墙')" left-arrow @click-left="onClickLeft" />
    <p style="font-size: 0.3rem; color: #121212; margin: 0.2rem 0 0 0.4rem">
      {{ $t("留言墙") }}
    </p>
    <ul style="height: 50vh; overflow: auto">
      <li v-for="(item, index) in lists" :key="index">
        <img :src="item.headimgUrl" alt="" />
        <div>
          <p>{{ item.nickName }}</p>
          <span>{{ item.leaveMessage }}</span>
        </div>
      </li>
    </ul>
    <div style="padding: 0 0.28rem; text-align: center">
      <van-field
        v-model="message"
        type="textarea"
        :placeholder="$t('请输入留言')"
      />
      <!-- 游客模式点击保存弹出登录提示 -->
      <van-button color="#14ABFB" :disabled="isDisabled" round @click="submit"
        >{{ $t("保存") }}
      </van-button>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import { Toast } from "vant";
import { mapState } from "vuex";
export default {
  props: {},
  setup() {
    const state = reactive({
      showBasic: false,
    });
    return { state };
  },
  name: "leaveWord",
  data() {
    return {
      message: "",
      lists: [],
      isDisabled: false,
      active: null,
    };
  },
  computed: {
    ...mapState({ homeData: (state) => state.homeData }),
  },
  created() {
    let userId = JSON.parse(sessionStorage.getItem("wxUser")) || null;
    this.active = this.$route.query.active;
    if (userId == null) {
      this.isDisabled = true;
      this.$toast.warn("请先登录");
      return false;
    }
    this.getMsg();
    console.log(this.homeData);
  },
  methods: {
    getMsg() {
      let params = new FormData();
      let positionId = this.homeData.id;
      params.append("positionId", positionId);
      this.$axios.post("/api/msg/listMsg", params).then((res) => {
        this.lists = res.rows;
        this.message = "";
        console.log(res, "1111111");
      });
    },
    //页面回退
    onClickLeft() {
      this.$router.go("-1");
    },
    submit() {
      console.log("23333");
      // Toast.loading({
      //   message: "加载中...",
      //   forbidClick: true,
      // });
      // setTimeout(() => {
      //   if (this.message != "") {
      //     this.lists.unshift({
      //       src: require("@/assets/images/logo.png"),
      //       name: "花儿与少年",
      //       mass: this.message,
      //     });
      //     Toast.success("提交成功");
      //     this.message = "";
      //   } else {
      //     Toast.fail("不能为空");
      //   }
      // }, 1000);
      // console.log(this.message);

      let params = new FormData();
      let userId = JSON.parse(sessionStorage.getItem("wxUser")) || null;
      // let userId = {
      //   id: "4",
      // };
      let msgType = "1"; //留言类型
      console.log("userId", userId);
      if (userId == null) {
        return false;
      }
      if (!this.message) {
        this.$toast.fail("留言失败");
      }
      params.append("content", this.message);
      let positionId = this.homeData.id;
      params.append("userId", userId.id);
      params.append("positionId", positionId);
      params.append("msgType", msgType);
      this.$axios.post("/api/msg/addMsg", params).then((res) => {
        console.log(res); // 缺少用户数据
        if (res.code == 0) {
          // 执行回调更新视图
          this.getMsg();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.leaveWord {
  padding-top: 0.9rem;
}
/** 导航栏 */
/deep/ .van-nav-bar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 2;
  i {
    font-size: 0.5rem;
    color: #000;
    margin-top: -0.01rem;
  }
  .van-nav-bar__title {
    font-weight: 500;
    font-size: 0.32rem;
    color: #000;
    margin-left: 0.9rem;
  }
}
//留言墙
li {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.2rem;
  img {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    margin-right: 0.15rem;
  }
  p {
    font-size: 0.28rem;
    font-weight: 600;
    margin-bottom: 0.02rem;
  }
  span {
    font-size: 0.24rem;
    color: rgba(18, 18, 18, 0.5);
  }
}
/deep/ .van-cell {
  padding: 0 0.2rem;
  background: rgba(255, 255, 255, 0.20000000298023224);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.10000000149011612);
  border-radius: 0.14rem;
  textarea {
    height: 2.5rem;
  }
}
/deep/.van-button {
  width: 70%;
  height: 0.7rem;
  margin: 0 auto;
  margin-top: 0.6rem;
}
</style>